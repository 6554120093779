/**
 * @version 1.0.0.0
 * @copyright Copyright ©  2020
 * @compiler Bridge.NET 17.10.1
 */
Bridge.assembly("VPAEngine", function ($asm, globals) {
    "use strict";

    Bridge.define("VPAEngine.ModelAnalysis", {
        $kind: "enum",
        statics: {
            fields: {
                Stocks: 0,
                DowJones: 1
            }
        }
    });

    Bridge.define("VPAEngine.VpaEngine", {
        methods: {
            Calculate: function (vpa, modelAnalysis) {
                var $step = 0,
                    $jumpFromFinally, 
                    MP, 
                    $t, 
                    $asyncBody = Bridge.fn.bind(this, function () {
                        for (;;) {
                            $step = System.Array.min([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250,251,252,253,254,255,256,257,258,259,260,261,262,263,264,265,266,267,268,269,270,271,272,273,274,275,276,277,278,279,280,281,282,283,284,285,286,287], $step);
                            switch ($step) {
                                case 0: {

                                }
                                case 1: {
                                    vpa.F = 0;
                                }
                                case 2: {
                                    vpa.DS = vpa.DSS;
                                    vpa.DS = 0.01 * vpa.DS;
                                    vpa.DE = vpa.DEE;
                                    vpa.DE = 0.01 * vpa.DE;
                                    vpa.RB = vpa.RBB;
                                    vpa.RB = 0.01 * vpa.RB;
                                    vpa.DRB = vpa.DRBB;
                                    vpa.DRB = 0.01 * vpa.DRB / vpa.RB;
                                    vpa.H = vpa.HH;
                                    vpa.S = vpa.SS;
                                    vpa.XLTD = vpa.XLTDD;
                                    if (vpa.DRB <= 0) {
                                        $step = 3;
                                        continue;
                                    } else  {
                                        $step = 4;
                                        continue;
                                    }
                                }
                                case 3: {
                                    $step = 7;
                                    continue;
                                }
                                case 4: {
                                    $step = 6;
                                    continue;
                                }
                                case 5: {

                                }
                                case 6: {
                                    vpa.AK3 = vpa.RB * (1 + vpa.DRB);
                                    $step = 8;
                                    continue;
                                }
                                case 7: {
                                    vpa.AK3 = vpa.RB;
                                }
                                case 8: {
                                    vpa.XLTD = (modelAnalysis === VPAEngine.ModelAnalysis.Stocks) ? (vpa.XLTD / vpa.S) : (modelAnalysis === VPAEngine.ModelAnalysis.DowJones) ? (vpa.XLTD / vpa.BV) : 0;
                                    vpa.RT = vpa.E1 / (vpa.BV + vpa.XLTD);
                                    vpa.XL = vpa.XLTD / (vpa.BV + vpa.XLTD);
                                    vpa.ZK = 1;
                                    if (vpa.BV <= 0) {
                                        $step = 9;
                                        continue;
                                    } else  {
                                        $step = 10;
                                        continue;
                                    }
                                }
                                case 9: {
                                    $step = 53;
                                    continue;
                                }
                                case 10: {
                                    $step = 12;
                                    continue;
                                }
                                case 11: {

                                }
                                case 12: {
                                    if ((vpa.E1 - 0.1 * vpa.BV) < 0) {
                                        $step = 13;
                                        continue;
                                    } else  {
                                        $step = 14;
                                        continue;
                                    }
                                }
                                case 13: {
                                    $step = 53;
                                    continue;
                                }
                                case 14: {
                                    $step = 16;
                                    continue;
                                }
                                case 15: {

                                }
                                case 16: {
                                    if ((vpa.DE - vpa.AK3) < 0) {
                                        $step = 17;
                                        continue;
                                    } else  {
                                        $step = 18;
                                        continue;
                                    }
                                }
                                case 17: {
                                    $step = 53;
                                    continue;
                                }
                                case 18: {
                                    $step = 20;
                                    continue;
                                }
                                case 19: {

                                }
                                case 20: {
                                    vpa.AK1 = -10 * (vpa.E1 / vpa.BV - 0.2);
                                    if ((vpa.E1 / vpa.BV - 0.2) <= 0) {
                                        $step = 21;
                                        continue;
                                    } else  {
                                        $step = 22;
                                        continue;
                                    }
                                }
                                case 21: {
                                    $step = 25;
                                    continue;
                                }
                                case 22: {
                                    $step = 24;
                                    continue;
                                }
                                case 23: {

                                }
                                case 24: {
                                    vpa.AK1 = 0;
                                }
                                case 25: {
                                    vpa.AK2 = -1.5 * (vpa.DE / vpa.AK3 - 1.67);
                                    if ((vpa.DE / vpa.AK3 - 1.67) <= 0) {
                                        $step = 26;
                                        continue;
                                    } else  {
                                        $step = 27;
                                        continue;
                                    }
                                }
                                case 26: {
                                    $step = 30;
                                    continue;
                                }
                                case 27: {
                                    $step = 29;
                                    continue;
                                }
                                case 28: {

                                }
                                case 29: {
                                    vpa.AK2 = 0;
                                }
                                case 30: {
                                    vpa.AK = 0.5 * (vpa.AK1 + vpa.AK2);
                                    vpa.XLH = vpa.XLTD / (vpa.BV + vpa.AK * vpa.XLTD);
                                    vpa.ZK = (1 + vpa.XLH) / (1 + vpa.XL);
                                    if ((vpa.ZK - 2) <= 0) {
                                        $step = 31;
                                        continue;
                                    } else  {
                                        $step = 32;
                                        continue;
                                    }
                                }
                                case 31: {
                                    $step = 35;
                                    continue;
                                }
                                case 32: {
                                    $step = 34;
                                    continue;
                                }
                                case 33: {

                                }
                                case 34: {
                                    vpa.ZK = 2;
                                }
                                case 35: {
                                    if ((1 + vpa.DE - vpa.ZK) <= 0) {
                                        $step = 36;
                                        continue;
                                    } else  {
                                        $step = 37;
                                        continue;
                                    }
                                }
                                case 36: {
                                    $step = 53;
                                    continue;
                                }
                                case 37: {
                                    $step = 39;
                                    continue;
                                }
                                case 38: {

                                }
                                case 39: {
                                    if ((0.5 - vpa.AK) <= 0) {
                                        $step = 40;
                                        continue;
                                    } else  {
                                        $step = 41;
                                        continue;
                                    }
                                }
                                case 40: {
                                    $step = 53;
                                    continue;
                                }
                                case 41: {
                                    $step = 43;
                                    continue;
                                }
                                case 42: {

                                }
                                case 43: {
                                    if ((0.4 - vpa.AK2) <= 0) {
                                        $step = 44;
                                        continue;
                                    } else  {
                                        $step = 45;
                                        continue;
                                    }
                                }
                                case 44: {
                                    $step = 53;
                                    continue;
                                }
                                case 45: {
                                    $step = 47;
                                    continue;
                                }
                                case 46: {

                                }
                                case 47: {
                                    if (vpa.AK <= 0) {
                                        $step = 48;
                                        continue;
                                    } else  {
                                        $step = 49;
                                        continue;
                                    }
                                }
                                case 48: {
                                    $step = 51;
                                    continue;
                                }
                                case 49: {
                                    $step = 52;
                                    continue;
                                }
                                case 50: {

                                }
                                case 51: {
                                    vpa.H = vpa.H + 1;
                                }
                                case 52: {
                                    vpa.ZK = 1;
                                    $step = 54;
                                    continue;
                                }
                                case 53: {
                                    vpa.H = vpa.H - 1;
                                }
                                case 54: {
                                    if (vpa.H <= 0) {
                                        $step = 55;
                                        continue;
                                    } else  {
                                        $step = 56;
                                        continue;
                                    }
                                }
                                case 55: {
                                    $step = 58;
                                    continue;
                                }
                                case 56: {
                                    $step = 59;
                                    continue;
                                }
                                case 57: {

                                }
                                case 58: {
                                    vpa.EQ = vpa.E1;
                                    $step = 64;
                                    continue;
                                }
                                case 59: {
                                    if (vpa.DE <= 0) {
                                        $step = 60;
                                        continue;
                                    } else  {
                                        $step = 61;
                                        continue;
                                    }
                                }
                                case 60: {
                                    $step = 58;
                                    continue;
                                }
                                case 61: {
                                    $step = 63;
                                    continue;
                                }
                                case 62: {

                                }
                                case 63: {
                                    vpa.EQ = vpa.E1 * Math.pow((1 + vpa.DE), vpa.H);
                                }
                                case 64: {
                                    if (vpa.DRB <= 0) {
                                        $step = 65;
                                        continue;
                                    } else  {
                                        $step = 66;
                                        continue;
                                    }
                                }
                                case 65: {
                                    $step = 69;
                                    continue;
                                }
                                case 66: {
                                    $step = 68;
                                    continue;
                                }
                                case 67: {

                                }
                                case 68: {
                                    vpa.V1 = (vpa.EQ * (1 + vpa.DE * (1 + vpa.XL)) / vpa.AK3) * vpa.ZK;
                                    $step = 70;
                                    continue;
                                }
                                case 69: {
                                    vpa.V1 = ((vpa.EQ * (1 + vpa.DE * (1 + vpa.XL) - vpa.DRB)) / vpa.AK3) * vpa.ZK;
                                }
                                case 70: {
                                    vpa.V2 = vpa.D / vpa.AK3;
                                    vpa.V4 = vpa.BV + vpa.XLTD;
                                    vpa.V5 = vpa.BV * (1 + vpa.DE);
                                    vpa.XK = vpa.RT / vpa.AK3;
                                    if (vpa.XK <= 0) {
                                        $step = 71;
                                        continue;
                                    } else  {
                                        $step = 72;
                                        continue;
                                    }
                                }
                                case 71: {
                                    $step = 74;
                                    continue;
                                }
                                case 72: {
                                    $step = 78;
                                    continue;
                                }
                                case 73: {

                                }
                                case 74: {
                                    if ((vpa.V2 - vpa.V5) <= 0) {
                                        $step = 75;
                                        continue;
                                    } else  {
                                        $step = 76;
                                        continue;
                                    }
                                }
                                case 75: {
                                    $step = 270;
                                    continue;
                                }
                                case 76: {
                                    $step = 268;
                                    continue;
                                }
                                case 77: {

                                }
                                case 78: {
                                    if ((vpa.DE - vpa.AK3) < 0) {
                                        $step = 79;
                                        continue;
                                    } else  {
                                        $step = 80;
                                        continue;
                                    }
                                }
                                case 79: {
                                    $step = 86;
                                    continue;
                                }
                                case 80: {
                                    $step = 82;
                                    continue;
                                }
                                case 81: {

                                }
                                case 82: {
                                    if ((vpa.V1 - vpa.V4) < 0) {
                                        $step = 83;
                                        continue;
                                    } else  {
                                        $step = 84;
                                        continue;
                                    }
                                }
                                case 83: {
                                    $step = 242;
                                    continue;
                                }
                                case 84: {
                                    $step = 267;
                                    continue;
                                }
                                case 85: {

                                }
                                case 86: {
                                    if (vpa.DE <= 0) {
                                        $step = 87;
                                        continue;
                                    } else  {
                                        $step = 88;
                                        continue;
                                    }
                                }
                                case 87: {
                                    $step = 157;
                                    continue;
                                }
                                case 88: {
                                    $step = 90;
                                    continue;
                                }
                                case 89: {

                                }
                                case 90: {
                                    if (vpa.DS > (vpa.AK3 + vpa.DE)) {
                                        vpa.XINC = (vpa.DS + vpa.DE) / (vpa.DS + vpa.AK3);
                                    } else {
                                        vpa.XINC = vpa.DE / vpa.AK3;
                                    }
                                    if (vpa.D <= 0) {
                                        $step = 91;
                                        continue;
                                    } else  {
                                        $step = 92;
                                        continue;
                                    }
                                }
                                case 91: {
                                    $step = 143;
                                    continue;
                                }
                                case 92: {
                                    $step = 94;
                                    continue;
                                }
                                case 93: {

                                }
                                case 94: {
                                    if ((vpa.V1 - vpa.V4) < 0) {
                                        $step = 95;
                                        continue;
                                    } 
                                    $step = 96;
                                    continue;
                                }
                                case 95: {
                                    $step = 126;
                                    continue;
                                }
                                case 96: {
                                    if ((vpa.V1 - vpa.V4) === 0) {
                                        $step = 97;
                                        continue;
                                    } else  {
                                        $step = 98;
                                        continue;
                                    }
                                }
                                case 97: {
                                    $step = 122;
                                    continue;
                                }
                                case 98: {
                                    $step = 100;
                                    continue;
                                }
                                case 99: {

                                }
                                case 100: {
                                    if ((vpa.V4 - vpa.V2) <= 0) {
                                        $step = 101;
                                        continue;
                                    } else  {
                                        $step = 102;
                                        continue;
                                    }
                                }
                                case 101: {
                                    $step = 108;
                                    continue;
                                }
                                case 102: {
                                    $step = 104;
                                    continue;
                                }
                                case 103: {

                                }
                                case 104: {
                                    vpa.VU = vpa.V1;
                                    vpa.VM = ((vpa.V1 * vpa.V2 / vpa.V4) + vpa.V2 + vpa.V1) / 3;
                                    vpa.VL = vpa.V2;
                                    if ((vpa.DS - 1.5 * vpa.AK3) > 0) {
                                        $step = 105;
                                        continue;
                                    } else  {
                                        $step = 106;
                                        continue;
                                    }
                                }
                                case 105: {
                                    $step = 142;
                                    continue;
                                }
                                case 106: {
                                    $step = 109;
                                    continue;
                                }
                                case 107: {

                                }
                                case 108: {
                                    vpa.VU = vpa.V1;
                                    vpa.VM = (vpa.V1 + vpa.V2) / 2;
                                    vpa.VL = vpa.V2;
                                }
                                case 109: {
                                    if ((vpa.DE - vpa.DRB) < 0) {
                                        $step = 110;
                                        continue;
                                    } else  {
                                        $step = 111;
                                        continue;
                                    }
                                }
                                case 110: {
                                    $step = 113;
                                    continue;
                                }
                                case 111: {
                                    $step = 138;
                                    continue;
                                }
                                case 112: {

                                }
                                case 113: {
                                    if ((vpa.D / vpa.E1 - 0.475) < 0) {
                                        $step = 114;
                                        continue;
                                    } else  {
                                        $step = 115;
                                        continue;
                                    }
                                }
                                case 114: {
                                    $step = 117;
                                    continue;
                                }
                                case 115: {
                                    $step = 137;
                                    continue;
                                }
                                case 116: {

                                }
                                case 117: {
                                    if ((vpa.XINC - vpa.D / vpa.E1) < 0) {
                                        $step = 118;
                                        continue;
                                    } else  {
                                        $step = 119;
                                        continue;
                                    }
                                }
                                case 118: {
                                    $step = 121;
                                    continue;
                                }
                                case 119: {
                                    $step = 137;
                                    continue;
                                }
                                case 120: {

                                }
                                case 121: {
                                    vpa.V = vpa.VL + (vpa.VM - vpa.VL) * (vpa.XINC - 0.52 * vpa.D / vpa.E1 + 0.25);
                                    $step = 275;
                                    continue;
                                }
                                case 122: {
                                    if ((vpa.V1 - vpa.V2) <= 0) {
                                        $step = 123;
                                        continue;
                                    } else  {
                                        $step = 124;
                                        continue;
                                    }
                                }
                                case 123: {
                                    $step = 268;
                                    continue;
                                }
                                case 124: {
                                    $step = 108;
                                    continue;
                                }
                                case 125: {

                                }
                                case 126: {
                                    if ((vpa.V1 - vpa.V2) <= 0) {
                                        $step = 127;
                                        continue;
                                    } else  {
                                        $step = 128;
                                        continue;
                                    }
                                }
                                case 127: {
                                    $step = 268;
                                    continue;
                                }
                                case 128: {
                                    $step = 130;
                                    continue;
                                }
                                case 129: {

                                }
                                case 130: {
                                    vpa.VU = vpa.V4;
                                    vpa.VM = vpa.V1;
                                    vpa.VL = vpa.V2;
                                    vpa.XINC = vpa.XINC * vpa.D / vpa.E1;
                                    if ((vpa.DS - 1.5 * vpa.AK3) < 0) {
                                        $step = 131;
                                        continue;
                                    } 
                                    $step = 132;
                                    continue;
                                }
                                case 131: {
                                    $step = 137;
                                    continue;
                                }
                                case 132: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) === 0) {
                                        $step = 133;
                                        continue;
                                    } else  {
                                        $step = 134;
                                        continue;
                                    }
                                }
                                case 133: {
                                    $step = 138;
                                    continue;
                                }
                                case 134: {
                                    $step = 136;
                                    continue;
                                }
                                case 135: {

                                }
                                case 136: {
                                    vpa.XINC = (vpa.DS + vpa.DE) / (vpa.DS + vpa.AK3);
                                    $step = 142;
                                    continue;
                                }
                                case 137: {
                                    vpa.V = vpa.VL + vpa.XINC * (vpa.VM - vpa.VL);
                                    $step = 275;
                                    continue;
                                }
                                case 138: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) > 0) {
                                        $step = 139;
                                        continue;
                                    } else  {
                                        $step = 140;
                                        continue;
                                    }
                                }
                                case 139: {
                                    $step = 142;
                                    continue;
                                }
                                case 140: {
                                    vpa.V = vpa.VM;
                                    $step = 141;
                                    continue;
                                }
                                case 141: {
                                    $step = 275;
                                    continue;
                                }
                                case 142: {
                                    MP = vpa.BV / vpa.V4;
                                    if (vpa.XLTD <= 0) {
                                        MP = 1;
                                    }
                                    vpa.V = vpa.VM + vpa.XINC * MP * (vpa.VU - vpa.VM);
                                    $step = 275;
                                    continue;
                                }
                                case 143: {
                                    if ((vpa.V1 - vpa.V4) <= 0) {
                                        $step = 144;
                                        continue;
                                    } else  {
                                        $step = 145;
                                        continue;
                                    }
                                }
                                case 144: {
                                    $step = 153;
                                    continue;
                                }
                                case 145: {
                                    $step = 147;
                                    continue;
                                }
                                case 146: {

                                }
                                case 147: {
                                    vpa.VU = vpa.V1;
                                    vpa.VM = (vpa.V1 + vpa.V4 + vpa.V5) / 3;
                                    vpa.VL = (vpa.V4 + vpa.V5) / 2;
                                }
                                case 148: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 149;
                                        continue;
                                    } else  {
                                        $step = 150;
                                        continue;
                                    }
                                }
                                case 149: {
                                    $step = 152;
                                    continue;
                                }
                                case 150: {
                                    $step = 136;
                                    continue;
                                }
                                case 151: {

                                }
                                case 152: {
                                    vpa.XINC = vpa.XINC * vpa.VL / vpa.VM;
                                    $step = 137;
                                    continue;
                                }
                                case 153: {
                                    vpa.VU = vpa.V4;
                                    vpa.VM = (vpa.V4 + vpa.V1 + vpa.V5) / 3;
                                    vpa.VL = (vpa.V1 + vpa.V5) / 2;
                                    if ((vpa.DE - vpa.DRB) <= 0) {
                                        $step = 154;
                                        continue;
                                    } else  {
                                        $step = 155;
                                        continue;
                                    }
                                }
                                case 154: {
                                    $step = 152;
                                    continue;
                                }
                                case 155: {
                                    $step = 148;
                                    continue;
                                }
                                case 156: {

                                }
                                case 157: {
                                    if (vpa.D <= 0) {
                                        $step = 158;
                                        continue;
                                    } else  {
                                        $step = 159;
                                        continue;
                                    }
                                }
                                case 158: {
                                    $step = 189;
                                    continue;
                                }
                                case 159: {
                                    $step = 161;
                                    continue;
                                }
                                case 160: {

                                }
                                case 161: {
                                    if ((vpa.DS + vpa.DE) <= 0) {
                                        $step = 162;
                                        continue;
                                    } else  {
                                        $step = 163;
                                        continue;
                                    }
                                }
                                case 162: {
                                    $step = 210;
                                    continue;
                                }
                                case 163: {
                                    $step = 165;
                                    continue;
                                }
                                case 164: {

                                }
                                case 165: {
                                    vpa.XINC = (vpa.DS / (vpa.DS + vpa.AK3)) * (vpa.DS + vpa.DE) / (vpa.DS - vpa.DE);
                                    if (vpa.DS < 2 * vpa.AK3) {
                                        vpa.XINC = vpa.XINC * 0.5 * vpa.DS / vpa.AK3;
                                    }
                                    if ((vpa.V1 - vpa.V4) < 0) {
                                        $step = 166;
                                        continue;
                                    } else  {
                                        $step = 167;
                                        continue;
                                    }
                                }
                                case 166: {
                                    $step = 180;
                                    continue;
                                }
                                case 167: {
                                    $step = 169;
                                    continue;
                                }
                                case 168: {

                                }
                                case 169: {
                                    if ((vpa.V4 - vpa.V2) <= 0) {
                                        $step = 170;
                                        continue;
                                    } else  {
                                        $step = 171;
                                        continue;
                                    }
                                }
                                case 170: {
                                    $step = 173;
                                    continue;
                                }
                                case 171: {
                                    $step = 184;
                                    continue;
                                }
                                case 172: {

                                }
                                case 173: {
                                    vpa.VU = vpa.V1;
                                    vpa.VM = (vpa.V1 + vpa.V2) / 2;
                                    vpa.VL = vpa.V2;
                                    $step = 185;
                                    continue;
                                }
                                case 174: {
                                    vpa.VU = vpa.V4;
                                    vpa.VM = vpa.V1;
                                    vpa.VL = vpa.V2;
                                }
                                case 175: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 176;
                                        continue;
                                    } else  {
                                        $step = 177;
                                        continue;
                                    }
                                }
                                case 176: {
                                    $step = 137;
                                    continue;
                                }
                                case 177: {
                                    $step = 179;
                                    continue;
                                }
                                case 178: {

                                }
                                case 179: {
                                    vpa.XINC = (vpa.V1 / vpa.V4) * vpa.XINC;
                                    $step = 142;
                                    continue;
                                }
                                case 180: {
                                    if ((vpa.V1 - vpa.V2) <= 0) {
                                        $step = 181;
                                        continue;
                                    } else  {
                                        $step = 182;
                                        continue;
                                    }
                                }
                                case 181: {
                                    $step = 268;
                                    continue;
                                }
                                case 182: {
                                    $step = 174;
                                    continue;
                                }
                                case 183: {

                                }
                                case 184: {
                                    vpa.VU = vpa.V1;
                                    vpa.VM = ((vpa.V1 * vpa.V2 / vpa.V4) + vpa.V2 + vpa.V1) / 3;
                                    vpa.VL = vpa.V2;
                                }
                                case 185: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 186;
                                        continue;
                                    } else  {
                                        $step = 187;
                                        continue;
                                    }
                                }
                                case 186: {
                                    $step = 137;
                                    continue;
                                }
                                case 187: {
                                    $step = 142;
                                    continue;
                                }
                                case 188: {

                                }
                                case 189: {
                                    if (vpa.DS <= 0) {
                                        $step = 190;
                                        continue;
                                    } else  {
                                        $step = 191;
                                        continue;
                                    }
                                }
                                case 190: {
                                    $step = 193;
                                    continue;
                                }
                                case 191: {
                                    $step = 198;
                                    continue;
                                }
                                case 192: {

                                }
                                case 193: {
                                    if ((vpa.V1 - vpa.V4) <= 0) {
                                        $step = 194;
                                        continue;
                                    } else  {
                                        $step = 195;
                                        continue;
                                    }
                                }
                                case 194: {
                                    $step = 197;
                                    continue;
                                }
                                case 195: {
                                    $step = 269;
                                    continue;
                                }
                                case 196: {

                                }
                                case 197: {
                                    vpa.V = (vpa.V1 + vpa.V5) / 2;
                                    $step = 275;
                                    continue;
                                }
                                case 198: {
                                    if ((vpa.V1 - vpa.V4) <= 0) {
                                        $step = 199;
                                        continue;
                                    } else  {
                                        $step = 200;
                                        continue;
                                    }
                                }
                                case 199: {
                                    $step = 206;
                                    continue;
                                }
                                case 200: {
                                    $step = 202;
                                    continue;
                                }
                                case 201: {

                                }
                                case 202: {
                                    vpa.VU = vpa.V1;
                                    vpa.VM = (vpa.V1 + vpa.V4 + vpa.V5) / 3;
                                    vpa.VL = (vpa.V4 + vpa.V5) / 2;
                                    vpa.XINC = (vpa.DS / (vpa.DS + vpa.AK3)) * (vpa.DS + vpa.DE) / (vpa.DS - vpa.DE);
                                    if (vpa.DS < 2 * vpa.AK3) {
                                        vpa.XINC = vpa.XINC * 0.5 * vpa.DS / vpa.AK3;
                                    }
                                    if ((vpa.DS + vpa.DE) <= 0) {
                                        $step = 203;
                                        continue;
                                    } else  {
                                        $step = 204;
                                        continue;
                                    }
                                }
                                case 203: {
                                    $step = 235;
                                    continue;
                                }
                                case 204: {
                                    $step = 185;
                                    continue;
                                }
                                case 205: {

                                }
                                case 206: {
                                    vpa.VU = vpa.V4;
                                    vpa.VM = (vpa.V4 + vpa.V1 + vpa.V5) / 3;
                                    vpa.VL = (vpa.V1 + vpa.V5) / 2;
                                    vpa.XINC = (vpa.DS / (vpa.DS + vpa.AK3)) * (vpa.DS + vpa.DE) / (vpa.DS - vpa.DE);
                                    if (vpa.DS < 2 * vpa.AK3) {
                                        vpa.XINC = vpa.XINC * 0.5 * vpa.DS / vpa.AK3;
                                    }
                                    if ((vpa.DS + vpa.DE) <= 0) {
                                        $step = 207;
                                        continue;
                                    } else  {
                                        $step = 208;
                                        continue;
                                    }
                                }
                                case 207: {
                                    $step = 235;
                                    continue;
                                }
                                case 208: {
                                    $step = 175;
                                    continue;
                                }
                                case 209: {

                                }
                                case 210: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 211;
                                        continue;
                                    } else  {
                                        $step = 212;
                                        continue;
                                    }
                                }
                                case 211: {
                                    $step = 227;
                                    continue;
                                }
                                case 212: {
                                    $step = 214;
                                    continue;
                                }
                                case 213: {

                                }
                                case 214: {
                                    if ((vpa.V1 - vpa.V4) < 0) {
                                        $step = 215;
                                        continue;
                                    } else  {
                                        $step = 216;
                                        continue;
                                    }
                                }
                                case 215: {
                                    $step = 222;
                                    continue;
                                }
                                case 216: {
                                    $step = 218;
                                    continue;
                                }
                                case 217: {

                                }
                                case 218: {
                                    if ((vpa.V1 - vpa.V2) < 0) {
                                        $step = 219;
                                        continue;
                                    } else  {
                                        $step = 220;
                                        continue;
                                    }
                                }
                                case 219: {
                                    $step = 268;
                                    continue;
                                }
                                case 220: {
                                    $step = 267;
                                    continue;
                                }
                                case 221: {

                                }
                                case 222: {
                                    if ((vpa.V1 - vpa.V2) <= 0) {
                                        $step = 223;
                                        continue;
                                    } else  {
                                        $step = 224;
                                        continue;
                                    }
                                }
                                case 223: {
                                    $step = 226;
                                    continue;
                                }
                                case 224: {
                                    $step = 241;
                                    continue;
                                }
                                case 225: {

                                }
                                case 226: {
                                    vpa.V = vpa.V2 + (vpa.V1 / vpa.V4) * (vpa.V4 - vpa.V2);
                                    $step = 275;
                                    continue;
                                }
                                case 227: {
                                    if (vpa.DS <= 0) {
                                        $step = 228;
                                        continue;
                                    } else  {
                                        $step = 229;
                                        continue;
                                    }
                                }
                                case 228: {
                                    $step = 268;
                                    continue;
                                }
                                case 229: {
                                    $step = 231;
                                    continue;
                                }
                                case 230: {

                                }
                                case 231: {
                                    if ((vpa.V1 - vpa.V2) <= 0) {
                                        $step = 232;
                                        continue;
                                    } else  {
                                        $step = 233;
                                        continue;
                                    }
                                }
                                case 232: {
                                    $step = 268;
                                    continue;
                                }
                                case 233: {
                                    $step = 266;
                                    continue;
                                }
                                case 234: {

                                }
                                case 235: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 236;
                                        continue;
                                    } else  {
                                        $step = 237;
                                        continue;
                                    }
                                }
                                case 236: {
                                    $step = 239;
                                    continue;
                                }
                                case 237: {
                                    $step = 240;
                                    continue;
                                }
                                case 238: {

                                }
                                case 239: {
                                    vpa.V = vpa.VL;
                                    $step = 275;
                                    continue;
                                }
                                case 240: {
                                    vpa.V = vpa.VM;
                                    $step = 275;
                                    continue;
                                }
                                case 241: {
                                    vpa.V = vpa.V1 + (vpa.V1 / vpa.V4) * (vpa.V4 - vpa.V1) / 2;
                                    $step = 275;
                                    continue;
                                }
                                case 242: {
                                    $step = 243;
                                    continue;
                                }
                                case 243: {
                                    if (vpa.D <= 0) {
                                        $step = 244;
                                        continue;
                                    } else  {
                                        $step = 245;
                                        continue;
                                    }
                                }
                                case 244: {
                                    $step = 261;
                                    continue;
                                }
                                case 245: {
                                    $step = 251;
                                    continue;
                                }
                                case 246: {

                                }
                                case 247: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 248;
                                        continue;
                                    } else  {
                                        $step = 249;
                                        continue;
                                    }
                                }
                                case 248: {
                                    $step = 255;
                                    continue;
                                }
                                case 249: {
                                    $step = 256;
                                    continue;
                                }
                                case 250: {

                                }
                                case 251: {
                                    if ((vpa.V1 - vpa.V2) < 0) {
                                        $step = 252;
                                        continue;
                                    } else  {
                                        $step = 253;
                                        continue;
                                    }
                                }
                                case 252: {
                                    $step = 247;
                                    continue;
                                }
                                case 253: {
                                    $step = 267;
                                    continue;
                                }
                                case 254: {

                                }
                                case 255: {
                                    vpa.VU = vpa.V4;
                                    vpa.VM = vpa.V2;
                                    vpa.XINC = vpa.DE / (vpa.DE + vpa.AK3);
                                    $step = 142;
                                    continue;
                                }
                                case 256: {
                                    vpa.XINC = vpa.DS / (vpa.DS + vpa.AK3);
                                    if ((vpa.DS - vpa.DE) < 0) {
                                        $step = 257;
                                        continue;
                                    } else  {
                                        $step = 258;
                                        continue;
                                    }
                                }
                                case 257: {
                                    $step = 255;
                                    continue;
                                }
                                case 258: {
                                    $step = 260;
                                    continue;
                                }
                                case 259: {

                                }
                                case 260: {
                                    vpa.VM = vpa.V2;
                                    vpa.VU = vpa.V4;
                                    $step = 142;
                                    continue;
                                }
                                case 261: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) <= 0) {
                                        $step = 262;
                                        continue;
                                    } else  {
                                        $step = 263;
                                        continue;
                                    }
                                }
                                case 262: {
                                    $step = 267;
                                    continue;
                                }
                                case 263: {
                                    $step = 265;
                                    continue;
                                }
                                case 264: {

                                }
                                case 265: {
                                    vpa.XINC = vpa.DE / (vpa.DE + vpa.AK3);
                                    vpa.VM = (vpa.V4 + vpa.V1) / 2;
                                    vpa.VU = vpa.V4;
                                    $step = 142;
                                    continue;
                                }
                                case 266: {
                                    vpa.XINC = vpa.DS / (vpa.DS + vpa.DE);
                                    vpa.V = vpa.V2 + vpa.XINC * (vpa.V1 - vpa.V2) / 2;
                                    $step = 275;
                                    continue;
                                }
                                case 267: {
                                    vpa.V = vpa.V1;
                                    $step = 275;
                                    continue;
                                }
                                case 268: {
                                    vpa.V = vpa.V2;
                                    $step = 275;
                                    continue;
                                }
                                case 269: {
                                    vpa.V = (vpa.V4 + vpa.V5) / 2;
                                    $step = 275;
                                    continue;
                                }
                                case 270: {
                                    if ((vpa.DS - 1.5 * vpa.AK3) > 0) {
                                        vpa.V = vpa.V5;
                                    } else {
                                        if (0.6 * vpa.V5 < vpa.D) {
                                            vpa.V = vpa.D;
                                        } else {
                                            vpa.V = 0.6 * vpa.V5;
                                        }
                                    }
                                    if (vpa.V < 0) {
                                        $step = 271;
                                        continue;
                                    } else  {
                                        $step = 272;
                                        continue;
                                    }
                                }
                                case 271: {
                                    $step = 274;
                                    continue;
                                }
                                case 272: {
                                    $step = 275;
                                    continue;
                                }
                                case 273: {

                                }
                                case 274: {
                                    vpa.V = 0.0;
                                }
                                case 275: {
                                    if (vpa.E1 <= 0) {
                                        $step = 276;
                                        continue;
                                    } else  {
                                        $step = 277;
                                        continue;
                                    }
                                }
                                case 276: {
                                    $step = 280;
                                    continue;
                                }
                                case 277: {
                                    $step = 279;
                                    continue;
                                }
                                case 278: {

                                }
                                case 279: {
                                    vpa.X1 = vpa.V / vpa.E1;
                                    $step = 281;
                                    continue;
                                }
                                case 280: {
                                    vpa.X1 = 0;
                                }
                                case 281: {
                                    if (vpa.V < vpa.BV) {
                                        vpa.V = vpa.V + (vpa.V / vpa.BV) * (vpa.BV - vpa.V);
                                    }
                                    if (vpa.XINC >= 0.6 && vpa.V < vpa.XINC * vpa.BV) {
                                        vpa.V = vpa.XINC * vpa.BV;
                                    } else {
                                        if (vpa.V < 0.6 * vpa.BV) {
                                            vpa.V = 0.6 * vpa.BV;
                                        }
                                    }
                                    if (vpa.E1 <= 0) {
                                        vpa.X1 = 0;
                                    } else {
                                        vpa.X1 = vpa.V / vpa.E1;
                                    }
                                    vpa.X2 = vpa.V / vpa.CP;
                                    if (vpa.E0 <= 0) {
                                        $step = 282;
                                        continue;
                                    } else  {
                                        $step = 283;
                                        continue;
                                    }
                                }
                                case 282: {
                                    $step = 286;
                                    continue;
                                }
                                case 283: {
                                    $step = 285;
                                    continue;
                                }
                                case 284: {

                                }
                                case 285: {
                                    vpa.X3 = vpa.CP / vpa.E0;
                                    $step = 287;
                                    continue;
                                }
                                case 286: {
                                    vpa.X3 = 0;
                                }
                                case 287: {
                                    vpa.X4 = (vpa.D / vpa.CP) * 100;

                                    if (vpa.X3 <= 0) {
                                        vpa.RRF = 0;
                                    } else {
                                        vpa.RRF = vpa.X1 / vpa.X3;
                                    }

                                    if (vpa.RRF < 0) {
                                        vpa.RRF = 0;
                                    }

                                    return ($t = new VPAEngine.VpaResultsVM(), $t.CP = vpa.CP, $t.E1 = vpa.E1, $t.STK = vpa.STK, $t.V = vpa.V, $t.X1 = vpa.X1, $t.X2 = vpa.X2, $t.X3 = vpa.X3, $t.X4 = vpa.X4, $t.RRF = vpa.RRF, $t);
                                }
                                default: {
                                    return;
                                }
                            }
                        }
                    }, arguments);

                return $asyncBody();
            }
        }
    });

    Bridge.define("VPAEngine.VpaResultsVM", {
        fields: {
            STK: null,
            V: 0,
            E1: 0,
            X1: 0,
            X2: 0,
            X3: 0,
            X4: 0,
            CP: 0,
            RRF: 0
        }
    });

    Bridge.define("VPAEngine.VpaUserVM", {
        fields: {
            STK: null,
            SS: 0,
            XLTDD: 0,
            D: 0,
            BV: 0,
            E1: 0,
            DEE: 0,
            DSS: 0,
            RBB: 0,
            DRBB: 0,
            E0: 0,
            CP: 0,
            HH: 0
        }
    });

    Bridge.define("VPAEngine.VpaSystemVM", {
        inherits: [VPAEngine.VpaUserVM],
        fields: {
            F: 0,
            DS: 0,
            DE: 0,
            RB: 0,
            DRB: 0,
            H: 0,
            S: 0,
            XLTD: 0,
            AK3: 0,
            RT: 0,
            XL: 0,
            ZK: 0,
            AK1: 0,
            AK2: 0,
            AK: 0,
            XLH: 0,
            EQ: 0,
            V1: 0,
            V2: 0,
            V4: 0,
            V5: 0,
            XK: 0,
            VU: 0,
            VM: 0,
            VL: 0,
            V: 0,
            XINC: 0,
            X1: 0,
            X2: 0,
            X3: 0,
            X4: 0,
            RRF: 0
        }
    });
});